import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import config from "../../utils/config";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import fieldValidator from "../../utils/field-validator";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";
import { useParams } from "react-router-dom";
import universities from "../../utils/universities";
import countries from "../../utils/countries";
import mobilityPrograms from "../../utils/mobility-programs";
import esnCountryConfig from "./esn-country-config";
import { DateTimePicker } from "@mui/x-date-pickers";
import { validateEmail } from "../../utils/email-validator";

function StudentRequest() {
  const { esnCountry } = useParams();

  const [region, setRegion] = useState("");
  const [organization, setOrganization] = useState("");
  const [university, setUniversity] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [passport, setPassport] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [photosAccepted, setPhotosAccepted] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [program, setProgram] = useState("");
  const [programDuration, setProgramDuration] = useState("");
  const [vat, setVat] = useState("");
  const [customQuestions, setCustomQuestions] = useState({});

  // Picker data
  const [allRegions, setAllRegions] = useState(new Set());
  const [universitiesByRegion, setUniversitiesByRegion] = useState({});
  const [organizationsByRegion, setOrganizationsByRegion] = useState({});
  const [organizationsByRegionAndUni, setOrganizationsByRegionAndUni] = useState({});
  const [universitiesByOrganization, setUniversitiesByOrganization] = useState({});
  const [possibleOrgs, setPossibleOrgs] = useState({});

  useEffect(() => {
    let newAllRegions = new Set();
    let newUniversitiesByRegion = {};
    let newOrganizationsByRegion = {};
    let newOrganizationsByRegionAndUni = {};
    let newUniversitiesByOrganization = {};

    for (let entry of Object.entries(universities[esnCountry])) {
      let org = entry[0];
      let regionDict = entry[1];

      for (let reg of Object.keys(regionDict)) {
        // All Regions
        newAllRegions.add(reg);

        // Universities by region
        if (!(reg in newUniversitiesByRegion)) {
          newUniversitiesByRegion[reg] = new Set();
        }

        for (let unis of Object.values(regionDict)) {
          for (let uni of unis) {
            newUniversitiesByRegion[reg].add(uni);
          }
        }

        // Organizations by region
        if (!(reg in newOrganizationsByRegion)) {
          newOrganizationsByRegion[reg] = new Set();
        }
        newOrganizationsByRegion[reg].add(org);

        // Organizations by region and university
        if (!(reg in newOrganizationsByRegionAndUni)) {
          newOrganizationsByRegionAndUni[reg] = {};
        }

        for (let unis of Object.values(regionDict)) {
          for (let uni of unis) {
            if (!(uni in newOrganizationsByRegionAndUni[reg])) {
              newOrganizationsByRegionAndUni[reg][uni] = new Set();
            }

            newOrganizationsByRegionAndUni[reg][uni].add(org);
          }
        }
      }

      // Universities by organization
      if (!(org in newUniversitiesByOrganization)) {
        newUniversitiesByOrganization[org] = new Set();
      }

      for (let unis of Object.values(regionDict)) {
        for (let uni of unis) {
          newUniversitiesByOrganization[org].add(uni);
        }
      }
    }

    // Set data
    setAllRegions(newAllRegions);
    setUniversitiesByRegion(newUniversitiesByRegion);
    setOrganizationsByRegion(newOrganizationsByRegion);
    setOrganizationsByRegionAndUni(newOrganizationsByRegionAndUni);
    setUniversitiesByOrganization(newUniversitiesByOrganization);
  }, [esnCountry]); // eslint-disable-line

  const getPossibleOrganizations = async (orgIds) => {
    if (orgIds == null) return;

    let result = {};

    try {
      for (let orgId of orgIds) {
        const res = await axios.get(`${config.API_ENDPOINT}/organizations/${orgId}`);
        result[res.data._id] = res.data;
      }
    } catch (err) {
      toast.error("An unexpected error has occurred");
      return;
    }

    setPossibleOrgs(result);
  };

  const loadDefaultAnswers = (organization) => {
    if (organization == null || organization === "") return;

    const org = possibleOrgs[organization];
    if (org == null) return;

    if (org.customEsnCardQuestions == null) return;

    let result = {};

    for (let q of org.customEsnCardQuestions) {
      if (q.type === "select-multi") {
        result[q.id] = [];
      } else {
        result[q.id] = "";
      }
    }

    setCustomQuestions(result);
  };

  const validateFields = () => {
    if (
      !fieldValidator.validateSimple([
        region,
        organization,
        university,
        name,
        gender,
        passport,
        email,
        phone,
        birthdate,
        address,
        country,
        termsAccepted,
        program,
        programDuration,
      ]) ||
      !customQuestionsFilled()
    ) {
      toast.error("You must fill all required fields.");
      return false;
    }

    if (!validateEmail(email)) {
      toast.error("Invalid email");
      return false;
    }

    return true;
  };

  const customQuestionsFilled = () => {
    if (organization == null || organization === "") return true;

    const org = possibleOrgs[organization];
    if (org == null) return true;

    if (org.customEsnCardQuestions == null) return true;

    for (let q of org.customEsnCardQuestions) {
      if (!q.required) continue;

      if (
        customQuestions[q.id] == null ||
        customQuestions[q.id] === "" ||
        (Array.isArray(customQuestions[q.id]) && customQuestions[q.id].length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const resetState = () => {
    setRegion("");
    setOrganization("");
    setUniversity("");
    setName("");
    setGender("");
    setPassport("");
    setEmail("");
    setPhone("");
    setBirthdate(null);
    setAddress("");
    setCountry("");
    setTermsAccepted(false);
    setPhotosAccepted(true);
    setNewsletterAccepted(true);
    setProgram("");
    setVat("");
    setProgramDuration("");
    setPossibleOrgs({});
    setCustomQuestions({});
  };

  const submitRequest = useDebouncedCallback(
    (pay) => {
      if (!validateFields()) return;

      let request = {
        name,
        gender,
        passport,
        email,
        phone,
        birthdate: birthdate.valueOf(),
        address,
        country,
        organization,
        university,
        photos: photosAccepted,
        newsletter: newsletterAccepted,
        timestamp: moment().valueOf(),
        vat,
        program,
        programDuration,
        customQuestions,
      };

      if (!pay) {
        axios
          .post(`${config.API_ENDPOINT}/student-requests/create`, request)
          .then(() => {
            toast.success("Request submitted. Please check your inbox.");
            resetState();
          })
          .catch((err) => {
            switch (err.response?.status) {
              case 400:
                toast.error("Invalid email format");
                break;
              case 409:
                toast.error("Email/Passport already in use.");
                break;
              default:
                toast.error("An unexpected error has occurred");
                break;
            }
          });
      } else {
        axios
          .post(`${config.API_ENDPOINT}/checkout/esncard`, {
            organization: request.organization,
            cancel: `${config.WEB_ENDPOINT}/iframes/student-request/${esnCountry}`,
            success: `${config.WEB_ENDPOINT}/iframes/purchase/esncard/${request.organization}`,
            metadata: {
              type: "card",
              data: request,
            },
          })
          .then(async (res) => {
            const result = res.data;

            switch (result.type) {
              case "stripe":
                const stripe = await loadStripe(result.publicKey);

                const redirect = await stripe.redirectToCheckout({
                  sessionId: result.session,
                });

                if (redirect.error) {
                  toast.error("Error opening the checkout");
                  console.error(redirect.error);
                }
                break;
              case "redsys":
                // Create a hidden form element programatically and submit it
                const form = document.createElement("form");
                form.method = "POST";
                form.action = result.url;
                form.style = "display: none";

                for (let key in result.body) {
                  const input = document.createElement("input");
                  input.type = "hidden";
                  input.name = key;
                  input.value = result.body[key];
                  form.appendChild(input);
                }

                document.body.appendChild(form);
                form.submit();
                break;
              default:
                toast.error("An unexpected error has occurred");
                break;
            }
          })
          .catch((err) => {
            switch (err.response?.status) {
              case 400:
                toast.error("Invalid request. Please verify your data.");
                break;
              case 401:
                toast.warning("This organization does not support online payments");
                break;
              case 409:
                toast.error("Email/Passport already in use.");
                break;
              default:
                toast.error("An unexpected error has occurred");
                break;
            }
          });
      }
    },
    500,
    { leading: true }
  );

  const extraQuestions = () => {
    if (organization == null || organization === "") return null;

    const org = possibleOrgs[organization];
    if (org == null) return null;

    if (org.customEsnCardQuestions == null || org.customEsnCardQuestions.length === 0) {
      return null;
    }

    return (
      <>
        {org.customEsnCardQuestions.map((q) => {
          switch (q.type) {
            case "select-multi":
            case "select":
              return (
                <FormControl
                  key={q.id}
                  fullWidth
                  sx={{ mb: 2 }}
                  variant="outlined"
                  required={q.required}
                >
                  <InputLabel style={{ backgroundColor: "#fff" }}>{q.question}</InputLabel>
                  <Select
                    value={customQuestions[q.id]}
                    multiple={q.type === "select-multi"}
                    onChange={(e) => {
                      let questions = { ...customQuestions };
                      questions[q.id] = e.target.value;
                      setCustomQuestions(questions);
                    }}
                  >
                    {q.values.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            case "text":
              return (
                <TextField
                  key={q.id}
                  variant="outlined"
                  required={q.required}
                  fullWidth
                  label={q.question}
                  name={q.id}
                  type="text"
                  value={customQuestions[q.id]}
                  onChange={(e) => {
                    let questions = { ...customQuestions };
                    questions[q.id] = e.target.value;
                    setCustomQuestions(questions);
                  }}
                  sx={{ mb: 2 }}
                />
              );
            case "datetime":
              return (
                <DateTimePicker
                  key={q.id}
                  variant="outlined"
                  required={q.required}
                  fullWidth
                  label={q.question}
                  name={q.id}
                  value={moment(customQuestions[q.id])}
                  onChange={(val) => {
                    let questions = { ...customQuestions };
                    questions[q.id] = moment(val).valueOf();
                    setCustomQuestions(questions);
                  }}
                  ampm={false}
                  renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
                  sx={{ mb: 2 }}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const studentInfoUI = () => {
    if (region == null || region === "") return null;
    if (university == null || university === "") return null;
    if (organization == null || organization === "") return null;

    const org = possibleOrgs[organization];
    if (org == null) return null;

    return (
      <div>
        <Typography style={{ marginBottom: 20, marginTop: 20 }}>
          Now you're ready to submit your information.
        </Typography>
        <Typography align="center" style={{ marginBottom: 10 }}>
          You are registering a ESNcard for the section <strong>{org.name}</strong>, at a cost of{" "}
          <strong>{org.esnCardPrice.toFixed(2)}€</strong>.
        </Typography>
        <Typography align="center" style={{ marginBottom: 10 }}>
          Proof of purchase of the ESNcard will be sent by email to the address specified below.
          Make sure that you write your email address correctly, and if it does not arrive, check
          your Spam / Unwanted email folder.
        </Typography>
        <Typography align="center" style={{ marginBottom: 10 }}>
          When you have finished your purchase, you will receive instructions on how we will
          validate the purchase give you your ESNcard.
        </Typography>
        <TextField
          variant="outlined"
          required
          fullWidth
          label="Full Name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 20 }}
        />
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>Gender</InputLabel>
          <Select value={gender} onChange={(e) => setGender(e.target.value)}>
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          required
          fullWidth
          label="ID/Passport"
          name="passport"
          type="text"
          autoComplete="off"
          value={passport}
          onChange={(e) => setPassport(e.target.value.trim())}
          style={{ marginBottom: 20 }}
        />
        <TextField
          variant="outlined"
          required
          fullWidth
          label="Email"
          name="email"
          type="email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
          style={{ marginBottom: 20 }}
        />
        <PhoneInput
          placeholder="Phone*"
          value={phone}
          onChange={(number) => setPhone(`+${number}`)}
          style={{ marginBottom: 20 }}
        />
        <DatePicker
          label="Birthdate"
          inputVariant="outlined"
          inputFormat="DD/MM/YYYY"
          value={birthdate}
          onChange={(date) => setBirthdate(date)}
          renderInput={(params) => <TextField {...params} required fullWidth sx={{ mb: 2 }} />}
        />
        {org.showVAT ? (
          <div>
            <TextField
              variant="outlined"
              fullWidth
              label="VAT"
              name="vat"
              type="text"
              value={vat}
              onChange={(e) => setVat(e.target.value.trim())}
            />
            <Typography style={{ fontSize: 10, marginBottom: 20 }}>
              You only need to insert a VAT number if you need an invoice. Invalid VAT numbers will
              be ignored! Please include the country code. (e.g. PT123456789)
            </Typography>
          </div>
        ) : null}
        <TextField
          variant="outlined"
          required
          fullWidth
          label="Address"
          name="address"
          type="text"
          autoComplete="off"
          value={address}
          onChange={(e) => setAddress(e.target.value.trim())}
          style={{ marginBottom: 20 }}
        />
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>Nationality</InputLabel>
          <Select value={country} onChange={(e) => setCountry(e.target.value)}>
            {countries.map((country) => (
              <MenuItem key={country.name} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>Mobility Program</InputLabel>
          <Select value={program} onChange={(e) => setProgram(e.target.value)}>
            {mobilityPrograms.map((program) => (
              <MenuItem key={program} value={program}>
                {program}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>Duration of the Program</InputLabel>
          <Select value={programDuration} onChange={(e) => setProgramDuration(e.target.value)}>
            <MenuItem value={"<1 month"}>{"<1 month"}</MenuItem>
            <MenuItem value={"1-3 months"}>1-3 months</MenuItem>
            <MenuItem value={"4-6 months (1 Semester)"}>{"4-6 months (1 Semester)"}</MenuItem>
            <MenuItem value={"7-12 months (1 Year)"}>{"7-12 months (1 Year)"}</MenuItem>
            <MenuItem value={"1-2 years"}>{"1-2 years"}</MenuItem>
            <MenuItem value={">2 years"}>{">2 years"}</MenuItem>
          </Select>
        </FormControl>
        {extraQuestions()}
        <Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />
        <Typography style={{ display: "inline-block" }}>
          By checking this box, you agree to the{" "}
          <a target="_blank" rel="noopener noreferrer" href={`/terms-conditions/${organization}`}>
            terms and conditions
          </a>
          . *
        </Typography>
        <br />
        <Checkbox checked={photosAccepted} onChange={(e) => setPhotosAccepted(e.target.checked)} />
        <Typography style={{ display: "inline-block" }}>
          By checking this box, you authorize the organization to take pictures of you during its
          activities which can be shared online for promotion of the association, free of charge and
          in a non-profit base.
        </Typography>
        <br />
        <Checkbox
          checked={newsletterAccepted}
          onChange={(e) => setNewsletterAccepted(e.target.checked)}
        />
        <Typography style={{ display: "inline-block" }}>
          By checking this box, you authorize the organization to send you emails notifying you of
          new activities and other promotions
        </Typography>
        <br />
        <Button
          style={{
            marginTop: 30,
            marginBottom: 10,
            color: "white",
          }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => submitRequest(false)}
        >
          PAY IN THE OFFICE
        </Button>
        <div>
          <Typography align="center">- or -</Typography>
          <Button
            style={{
              marginTop: 10,
              marginBottom: 50,
              color: "white",
            }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => submitRequest(true)}
          >
            PAY NOW
          </Button>
        </div>
      </div>
    );
  };

  const regionPicker = () => {
    return (
      <>
        <Typography style={{ marginBottom: 10 }}>First, select the region.</Typography>
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel id="region-label">Region</InputLabel>
          <Select
            labelId="region-label"
            label="Region"
            value={region}
            onChange={(e) => {
              let reg = e.target.value;
              setRegion(reg);
              setUniversity("");
              setOrganization("");

              if (esnCountryConfig[esnCountry].orgFirst) {
                setOrganization("");
                setPossibleOrgs({});
                getPossibleOrganizations(organizationsByRegion[reg]);
              }
            }}
          >
            {[...allRegions].map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const secondPicker = () => {
    if (region == null || region === "") {
      return null;
    }

    if (esnCountryConfig[esnCountry].orgFirst) {
      return organizationPicker();
    } else {
      return universityPicker(universitiesByRegion[region]);
    }
  };

  const thirdPicker = () => {
    if (region == null || region === "") {
      return null;
    }

    if (esnCountryConfig[esnCountry].orgFirst) {
      if (organization == null || organization === "") {
        return null;
      }

      return universityPicker(universitiesByOrganization[organization]);
    } else {
      if (university == null || university === "") {
        return null;
      }

      return organizationPicker();
    }
  };

  const universityPicker = (universitiesList) => {
    return (
      <div>
        <Typography style={{ marginBottom: 10 }}>Now, select the university.</Typography>
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>University</InputLabel>
          <Select
            value={university}
            onChange={(e) => {
              let uni = e.target.value;
              setUniversity(uni);

              if (!esnCountryConfig[esnCountry].orgFirst) {
                setOrganization("");
                setPossibleOrgs({});
                getPossibleOrganizations(organizationsByRegionAndUni[region][uni]);
              }
            }}
          >
            {[...universitiesList].map((uni) => (
              <MenuItem key={uni} value={uni}>
                {uni}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const organizationPicker = () => {
    return (
      <div>
        <Typography style={{ marginBottom: 10 }}>Now, select the ESN organization.</Typography>
        <FormControl fullWidth style={{ marginBottom: 20 }} variant="outlined" required>
          <InputLabel style={{ backgroundColor: "#fff" }}>ESN Organization</InputLabel>
          <Select
            value={organization}
            onChange={(e) => {
              setOrganization(e.target.value);

              loadDefaultAnswers(e.target.value);

              if (esnCountryConfig[esnCountry].orgFirst) {
                setUniversity("");
              }
            }}
          >
            {Object.values(possibleOrgs).map((org) => (
              <MenuItem key={org._id} value={org._id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <Typography style={{ fontSize: 20 }}>Hello! Let's get started, shall we?</Typography>
      <br />
      {regionPicker()}
      {secondPicker()}
      {thirdPicker()}
      {studentInfoUI()}
      {esnCountryConfig[esnCountry]?.supportEmail == null ? null : (
        <Typography style={{ marginBottom: 10, fontSize: 13 }}>
          {`If you have any problems during the purchase process, please, email us
				at ${esnCountryConfig[esnCountry].supportEmail}.`}
        </Typography>
      )}
    </div>
  );
}

export default StudentRequest;
